import React from "react"
import { Link, graphql } from "gatsby"
// https://www.npmjs.com/package/react-helmet
import Helmet from "react-helmet"
import Layout from "../components/layout"
import CrayonsStory from "../components/crayons-story"
import SEO from "../components/seo"

const postsPagination = props => {
  const { data } = props
  const { totalPage, currentPage, limit, skip } = props.pageContext

  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  // all nodes
  const nodes = data.allMarkdownRemark.nodes;
  // all posts contain /posts/*.md and /src/pages/*.md
  const allMarkdownFile = data.allMarkdownRemark.edges

  const postsNodesSlugs = nodes
    .filter(node => !node.fileAbsolutePath.includes('src/pages/'))
    .map(node => node.fields.slug);

  // all /src/pages/*md pages
  // const pages = allMarkdownFile.filter(item => pagesNodesSlugs.includes(item.node.fields.slug))
  // all /posts/*.md posts
  const allPosts = allMarkdownFile.filter(item => postsNodesSlugs.includes(item.node.fields.slug));
  const posts = allPosts.slice(skip, skip + limit);

  return (
    <Layout>
      <SEO title={'posts-pagination'} description={'posts-pagination'} />
      <Helmet
        htmlAttributes={{ lang: "en" }}
        meta={[{ name: "description", content: siteDescription }]}
        title={siteTitle}
      />
      <article
        style={{
          // border: `1px solid black`,
          borderRadius: `3px`,
          // paddingTop: '3.5rem',
          paddingTop: '1rem',
          marginBottom: `0.5rem`,
          // background: `white`,
          fontSize: `1rem`,
        }}
      >
        <section>
          {
            posts.map((post, idx) => {
              const { node } = post
              const title = node.frontmatter.title || node.fields.slug
              const date = node.frontmatter.date
              const update = node.frontmatter.update
              const slug = node.fields.slug
              const tags = node.frontmatter.tags || []
              const url = node.frontmatter.url || ""
              const comments = node.frontmatter.comments
              const timeToRead = node.timeToRead

              return (
                <CrayonsStory
                  key={idx}
                  title={title}
                  date={date}
                  update={update}
                  slug={slug}
                  tags={tags}
                  postUrl={url}
                  comments={comments}
                  timeToRead={timeToRead}
                />
              )
            })
          }
        </section>
        <section
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            listStyle: "none",
            padding: 0,
            marginTop: "2rem",
            paddingBottom: '2rem',
          }}
        >
          <div>
            {currentPage - 1 > 0 && (
              <Link
                to={"/posts/" + (currentPage - 1 === 1 ? "" : currentPage - 1)}
                rel="prev"
              >
                ← 上一页
              </Link>
            )}
          </div>
          <div>
            {currentPage + 1 <= totalPage && (
              <Link to={"/posts/" + (currentPage + 1)} rel="next">
                下一页 →
              </Link>
            )}
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default postsPagination

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        fileAbsolutePath
      }
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            update(formatString: "MMMM DD, YYYY")
            title
            tags
            url
            comments
          }
          timeToRead
        }
      }
    }
  }
`
